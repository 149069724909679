const { API_ENDPOINTS } = require('./api.constants')

const postData = async ({ endpoint, data, onResponse, onError, onFinally }) =>
    fetch(endpoint, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((response) => onResponse && onResponse(response))
        .catch((error) => {
            onError && onError(error)
        })
        .finally(() => {
            onFinally && onFinally()
        })

const sendEarlyAccessRequest = async ({ email, onError, onFinally }) =>
    postData({
        endpoint: API_ENDPOINTS.EARLY_ACCESS_REQUEST,
        data: { email },
        onError,
        onFinally,
    })

const sendCrowdfundingPreRegister = async ({ data, onError, onFinally }) =>
    postData({
        endpoint: API_ENDPOINTS.CROWDFUNDING_PRE_REGISTER,
        data,
        onError,
        onFinally,
    })

const sendEnterpriseContact = async ({ data, onError, onFinally }) =>
    postData({
        endpoint: API_ENDPOINTS.ENTERPRISE_CONTACT,
        data,
        onError,
        onFinally,
    })

export {
    sendEarlyAccessRequest,
    sendCrowdfundingPreRegister,
    sendEnterpriseContact,
}
