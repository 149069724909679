import React from 'react'

import { Link, NavLink } from 'react-router-dom'

function Header({ hideRightNavigation }) {
    return (
        <header className="site-header container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand rnwl-logo" to="/">
                    <img src="images/rnwl-logo.svg" alt="" />
                </Link>
                {!hideRightNavigation && (
                    <>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div
                            className="collapse navbar-collapse justify-content-end"
                            id="navbarSupportedContent"
                        >
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink
                                        to="/"
                                        id="link-home"
                                        className="nav-link"
                                    >
                                        For you
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/for-enterprise"
                                        id="link-enterprise"
                                        className="nav-link"
                                    >
                                        For enterprise
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="https://rnwl.page.link/get"
                                        id="btn-join"
                                        className="nav-link btn btn-secondary"
                                    >
                                        Get Rnwl
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </>
                )}
            </nav>
        </header>
    )
}

export default Header
