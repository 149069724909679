import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../Header'

import ScrollToTop from '../ScrollToTop'

function WeAreHiringPage() {
    return (
        <div>
            <ScrollToTop />
            <div className="hero hero--home">
                <Header />
                <div className="strip home-intro crowdfunding-padding crowdfunding-home-intro">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="text-spacer">
                                    <h1 className="tagline">Work at Rnwl</h1>

                                    <p>
                                        We’re growing at 30% a month, and we are
                                        starting a new and fascinating phase for
                                        the company. We are looking for talented
                                        and ambitious developers to join the
                                        team.
                                    </p>
                                    <p>
                                        You will join a highly experienced, fun
                                        and driven team where you will be
                                        instrumental in shaping the technical
                                        approach that will forever transform the
                                        consumer insurance experience. We are
                                        here to make Rnwl a great company and to
                                        have fun along the way.
                                    </p>
                                    <br></br>
                                    <h3>Open positions</h3>
                                    <br />
                                    <p>
                                        <ul>
                                            <li>
                                                <Link to="/mlengineer">
                                                    Machine learning engineer
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/mobiledev">
                                                    Mobile app developer
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/backenddev">
                                                    Back-end developer
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/fullstackdev">
                                                    Full-stack developer
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/frontenddev">
                                                    Front-end developer
                                                </Link>
                                            </li>
                                        </ul>
                                    </p>
                                    <p></p>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex">
                                <img
                                    className="sunset-scoot img-fluid"
                                    src="images/sunset-scoot.png"
                                    alt="woman and child scooting"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="crowdfunding-faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <br />
                            <br />
                            <h3>We're remote-first</h3>
                            <br />
                            <p>
                                Although some of us are based in Cambridge, UK
                                we operate remotely. We meet up in Cambridge for
                                social events and collaborative sessions every
                                few months. It's an excellent opportunity to
                                have fun, learn and share ideas with the team.
                            </p>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WeAreHiringPage
