import React from 'react'

import Header from '../Header'
import ScrollToTop from '../ScrollToTop'

function AppPage() {
    return (
        <div>
            <ScrollToTop />
            <div className="hero hero--home">
                <Header />
                <div className="strip home-intro">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <h1 className="tagline">
                                    Get Rnwl
                                    <br />
                                    and relax.
                                </h1>
                                <p className="intro">
                                    Scan the QR code with your phone <br />
                                    to download Rnwl now 👇
                                </p>
                                <div className="rnwl-qr-holder">
                                    <img
                                        className="rnwl-qr"
                                        src="images/rnwl-qr.svg"
                                        alt="Download Rnwl"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img
                                    className="hero-phone-app"
                                    src="images/phone-2.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppPage
