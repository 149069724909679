import React from 'react'

import Header from '../Header'
import ScrollToTop from '../ScrollToTop'

function MainPage() {
    return (
        <div>
            <ScrollToTop />
            <div className="hero hero--home">
                <Header />
                <div className="strip home-intro">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <h1 className="tagline">
                                    Your insurance world at&nbsp;your
                                    fingertips.
                                </h1>
                                <p className="intro">
                                    It's too easy to lose track of what's
                                    insured, by whom or when it renews. Rnwl's
                                    insurance wallet automatically organises all
                                    your insurance for you.
                                </p>

                                <a
                                    href="https://apps.apple.com/us/app/rnwl/id1562164202"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="app-store-link"
                                    title="Download on the iOS App Store"
                                >
                                    <img
                                        className="app-store-link__image"
                                        src="images/app-store-apple.png"
                                        alt="Download on the iOS App Store"
                                    />
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=co.rnwl.app"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="app-store-link"
                                    title="Get it on Google Play"
                                >
                                    <img
                                        className="app-store-link__image"
                                        src="images/app-store-google-play.png"
                                        alt="Get it on Google Play"
                                    />
                                </a>
                            </div>
                            <div className="col-md-6 hero-phone-wrapper">
                                <img
                                    className="hero-phone"
                                    src="images/hero-phone.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="strip benefits">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12"></div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-sm-4">
                            <div className="review">
                                <img
                                    src="images/review-stars.svg"
                                    alt=""
                                    className="review-stars"
                                />
                                <div className="review-text">
                                    <p>
                                        I gave some feedback about my experience
                                        on the app, including a suggestion I
                                        thought would improve it. Three days
                                        later my suggestion was implemented! I
                                        find this app to be an excellent way of
                                        keeping all of my insurances together so
                                        as to streamline renewals and stop me
                                        forgetting and scrambling at the last
                                        minute for cover.
                                    </p>
                                </div>
                                <div className="review-source">
                                    <p>
                                        Matt Honhold,{' '}
                                        <a
                                            target="blank"
                                            href="https://play.google.com/store/apps/details?id=co.rnwl.app"
                                        >
                                            Google Play
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="review">
                                <img
                                    src="images/review-stars.svg"
                                    alt=""
                                    className="review-stars"
                                />
                                <div className="review-text">
                                    <p>
                                        Rnwl is a brilliant app, so
                                        straightforward and useful. I raised a
                                        query with the developer and received an
                                        immediate response.
                                    </p>
                                </div>
                                <div className="review-source">
                                    <p>
                                        TP63,{' '}
                                        <a
                                            target="blank"
                                            href="https://apps.apple.com/us/app/rnwl/id1562164202"
                                        >
                                            Apple App Store
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="review">
                                <img
                                    src="images/review-stars.svg"
                                    alt=""
                                    className="review-stars"
                                />
                                <div className="review-text">
                                    <p>
                                        Fantastic app for keeping pretty much
                                        all details to do with your car up to
                                        date, also offers some fantastic deals
                                        at the same time. Have already
                                        recommended this app to friends and
                                        family and will continue to do so, it is
                                        a really handy app.
                                    </p>
                                </div>
                                <div className="review-source">
                                    <p>
                                        Daniel Christopher Band,{' '}
                                        <a
                                            target="blank"
                                            href="https://play.google.com/store/apps/details?id=co.rnwl.app"
                                        >
                                            Google Play
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 logo-strip logo-strip--featured-in d-flex">
                            <h4 className="logo-strip__text">Featured in</h4>
                            <img
                                src="images/express-logo.png"
                                width="300px"
                                alt="The Daily Express"
                                className="logo-strip__logo"
                            />
                            <img
                                src="images/tnw.png"
                                width="100px"
                                alt="The Next Web"
                                className="logo-strip__logo"
                            />
                            <img
                                src="images/alt-fi.png"
                                width="50px"
                                alt="Alt-fi"
                                className="logo-strip__logo"
                            />
                            <img
                                src="images/forbes.png"
                                width="120px"
                                alt="Forbes"
                                className="logo-strip__logo"
                            />
                            <img
                                src="images/the-times-logo.png"
                                width="300px"
                                alt="The Times"
                                className="logo-strip__logo"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="strip ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 offset-md-1 strip-image">
                            <img
                                src="images/confused-person.png"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 offset-md-1 align-middle">
                            <h3 className="">
                                Buying and managing insurance is broken
                            </h3>
                            <p>
                                It's too easy to lose track of what's insured,
                                by whom or when it renews.
                            </p>
                            <p>
                                The problem is that the industry is focused on
                                selling products, not on serving customers.
                            </p>

                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <div className="strip crowdfunding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-middle">
                            <h3 className="crowdfunding__title">
                                We make it easy for you
                            </h3>
                            <p>
                                Rnwl's insurance wallet automatically organises
                                all your insurance information and conveniently
                                puts it at your fingertips.
                            </p>
                            <p>
                                It reminds you when it's time to act, including
                                breakdown support, paying road tax, booking an
                                MOT or cancelling an auto-renewal.
                            </p>
                            <p>
                                It even makes it easy for you to look for a
                                better deal.
                            </p>
                        </div>
                        <div className="col-md-6 strip-image">
                            <img src="images/mug.png" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="strip">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <h2>The product</h2>
                        </div>
                    </div>
                    <div className="the-product-screenshots col">
                        <img
                            src="images/the-product-screenshot-rnwl-logo.png"
                            alt=""
                            className="ps-image the-product-screenshot"
                        />
                        <div className="tp tp--1">
                            <h3>Everything in one place</h3>
                            <p>
                                Always know what's insured, by whom and when it
                                renews.
                            </p>
                        </div>
                        <img
                            src="images/the-product-notifications-rnwl-logo.png"
                            alt=""
                            className="ps-image the-product-notifications"
                        />
                        <div className="tp tp--2">
                            <h3>Customer-focused features</h3>
                            <p>
                                Breakdown support, pay tax, book MOT or cancel
                                an auto-renewal.
                            </p>
                        </div>

                        <img
                            src="images/the-product-customer-focused.png"
                            alt=""
                            className="ps-image the-product-customer-focused"
                        />
                        <div className="tp tp--3">
                            <h3>Relevant reminders</h3>
                            <p>
                                Exactly when they’re needed. Making insurance
                                one less thing to think about.
                            </p>
                        </div>
                        <img
                            src="images/the-product-mortgage.png"
                            alt=""
                            className="ps-image the-product-mortgage"
                        />
                        <div className="tp tp--4">
                            <h3>Mortgage reminder</h3>
                            <p>
                                Keep track of when your deal ends and make sure
                                you stay on the best rates.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="strip strip--a-better-experience">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <h2 className="mb-5">A better experience</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-3 mb-4">
                            <img
                                src="images/email-1.png"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                            <img
                                src="images/email-2.png"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                            <img
                                src="images/email-3.png"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-sm-6 col-md-3 mb-4">
                            <img
                                src="images/email-4.png"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <h4>Never fill in a form ever again</h4>
                            <p>
                                Simply forward your insurance emails. Our clever
                                tech does the rest.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="strip strip--love-this-app">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-sm-6">
                            <img
                                src="images/love-this-app.png"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col d-flex">
                        <h2 className="how-it-works-title mx-auto">
                            Help when you need it the most
                        </h2>
                    </div>
                </div>
            </div>

            <div className="how-it-works how-it-works--step-1">
                <span className="line-top"></span>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 how-it-works__col offset-md-1">
                            <img
                                src="images/phone-save@2x.png"
                                alt="money saving email"
                                className="how-it-works__image img-fluid"
                            />
                        </div>
                        <div className="col-md-5 how-it-works__col how-it-works__content">
                            <h3 className="how-it-works__title">
                                Get deals from over a hundred insurers
                            </h3>
                            <p className="how-it-works__text">
                                We remind you about your insurance renewal at
                                just the right time to get the best prices.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="how-it-works how-it-works--step-2">
                <span className="line-middle"></span>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 order-md-2 how-it-works__col">
                            <img
                                src="images/car-breakdown.png"
                                alt="house and car"
                                className="how-it-works__image img-fluid"
                            />
                        </div>
                        <div className="col-md-5 how-it-works__col offset-md-1 how-it-works__content">
                            <h3 className="how-it-works__title">
                                Get breakdown help even when you don't have
                                cover
                            </h3>
                            <p className="how-it-works__text">
                                We've made it easy to contact your provider.
                                Don't have breakdown cover? No worries, you can
                                still get help in the app if you get stuck.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="how-it-works how-it-works--step-3">
                <span className="line-bottom"></span>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 how-it-works__col offset-md-1">
                            <img
                                src="images/the-switch.png"
                                alt="insurance switch"
                                className="how-it-works__image img-fluid"
                            />
                        </div>
                        <div className="col-md-5 how-it-works__col how-it-works__content">
                            <h3 className="how-it-works__title">
                                Cancel your previous insurance easily
                            </h3>
                            <p className="how-it-works__text">
                                Once you've found a new deal you can use the
                                Rnwl app to easily cancel your previous insurer.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <a href="https://rnwl.page.link/get">
                                <button
                                    className="hero-form__btn btn btn-primary mt-4"
                                    type="submit"
                                >
                                    Get Rnwl now
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="strip sunset">
                <div className="container">
                    <div className="row">
                        <div className="col d-flex">
                            <h2 className="mx-auto sunset__title">
                                Come along for the ride
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <p className="intro">
                                We're building the insurance app of the future
                                and we can only do it with your help.
                            </p>
                            <ul className="features-list">
                                <li>
                                    Join us today and be part of building
                                    something amazing.
                                </li>
                                <li>Vote on upcoming features.</li>
                                <li>Give us feedback and share ideas.</li>
                            </ul>
                            <a href="https://rnwl.page.link/get">
                                <button
                                    className="hero-form__btn btn btn-secondary mb-4"
                                    type="submit"
                                >
                                    Get Rnwl
                                </button>
                            </a>
                        </div>
                        <div className="col-md-6 d-flex">
                            <img
                                className="sunset-scoot img-fluid"
                                src="images/sunset-scoot.png"
                                alt="woman and child scooting"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainPage
