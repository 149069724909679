import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import AboutUsPage from './components/pages/AboutUsPage'
import AppPage from './components/pages/AppPage'
import BackendDev from './components/pages/BackendDev'
import DeleteAccount from './components/pages/DeleteAccount'
import EnterprisePage from './components/pages/EnterprisePage'
import FAQsPage from './components/pages/FAQsPage'
import FrontendDev from './components/pages/FrontendDev'
import FullstackDev from './components/pages/FullstackDev'
import JoinPage from './components/pages/JoinPage'
import MainPage from './components/pages/MainPage'
import MLEngineer from './components/pages/MLEngineer'
import MobileDev from './components/pages/MobileDev'
import NotFound from './components/pages/NotFound'
import PrivacyPage from './components/pages/PrivacyPage'
import TermsAndConditionsPage from './components/pages/TermsAndConditionsPage'
import WeAreHiringPage from './components/pages/WeAreHiringPage'

function Pages() {
    const location = useLocation()

    // Route changes:
    useEffect(() => {
        // On route change event: location.pathname
    }, [location.pathname])

    // Location hash changes:
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(
                location.hash.replace('#', '')
            )
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView()
                }, 300)
            }
        }
    }, [location.hash])

    return (
        <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/faqs" element={<FAQsPage />} />
            <Route path="/we-are-hiring" element={<WeAreHiringPage />} />
            <Route path="/mlengineer" element={<MLEngineer />} />
            <Route path="/mobiledev" element={<MobileDev />} />
            <Route path="/backenddev" element={<BackendDev />} />
            <Route path="/frontenddev" element={<FrontendDev />} />
            <Route path="/fullstackdev" element={<FullstackDev />} />
            <Route path="/join" element={<JoinPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/app" element={<AppPage />} />
            <Route path="/delete-your-account" element={<DeleteAccount />} />
            <Route
                path="/terms-and-conditions"
                element={<TermsAndConditionsPage />}
            />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/for-enterprise" element={<EnterprisePage />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate replace to="/not-found" />} />
        </Routes>
    )
}

export default Pages
