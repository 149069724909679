import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../Header'
import ScrollToTop from '../ScrollToTop'

function FrontendDev() {
    return (
        <div>
            <ScrollToTop />

            <div className="crowdfunding-faq">
                <Header />
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <br />
                            <Link to="/we-are-hiring">
                                &#8592; View all positions
                            </Link>
                            <br />
                            <br />
                            <h1>Front-end Developer</h1>
                            <br />
                            <p>
                                This role is a unique and exciting opportunity
                                for an experienced front-end developer to join a
                                start-up in the early formative days. Rnwl is an
                                Insurtech applying machine learning to
                                insurance. You will join a highly experienced,
                                fun and driven team where you will be
                                instrumental in shaping the technical approach
                                that will forever transform the consumer
                                insurance experience. We are here to make Rnwl a
                                great company and to have fun along the way.
                            </p>
                            <p>
                                Check out our{' '}
                                <a href="/app">iOS and Android apps</a> on the
                                app stores.
                            </p>
                            <br />
                            <div className="text-spacer">
                                <h4>Summary</h4>

                                <p>
                                    <ul>
                                        <li>Role: Front-end developer</li>
                                        <li>
                                            Location: Remote and/or Cambridge,UK
                                        </li>
                                        <li>Type: Full-time, permanent</li>
                                        <li>
                                            Salary: Negotiable depending on the
                                            level of experience
                                        </li>
                                        <li>Tech: ReactJS</li>
                                    </ul>
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>Your role</h4>

                                <p>
                                    You will play a key part in the front-end
                                    development.
                                </p>

                                <p>
                                    You will be part of the team leading the
                                    entire tech solution behind Rnwl. The team
                                    is responsible for the slick and highly
                                    user-friendly app, the powerful but robust
                                    back-end, and the unique machine learning
                                    project.
                                </p>

                                <p>
                                    You will report to the Head of Engineering.
                                    However, you will quickly find that our
                                    friendly and helpful culture means you will
                                    often work collaboratively with people
                                    across the organisation including members of
                                    the engineering team, product design team
                                    and the CEO.
                                </p>

                                <p>
                                    You may also get involved in some or all of
                                    the following:
                                    <ul>
                                        <li>
                                            Mobile app development (React
                                            Native)
                                        </li>
                                        <li>
                                            Cloud deployments (AWS, Docker...)
                                        </li>
                                        <li>NLP and machine learning</li>
                                    </ul>
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>What we are looking for</h4>
                                <p>
                                    You will be highly proactive with a
                                    pragmatic, solution-seeking attitude towards
                                    the problems you will encounter daily.
                                </p>
                                <p>
                                    <ul>
                                        <li>
                                            Minimum 3 years of relevant
                                            experience
                                        </li>
                                        <li>Very high standard of work</li>
                                        <li>
                                            Ability to work independently and
                                            under own initiative
                                        </li>
                                        <li>
                                            Willingness to communicate and
                                            contribute ideas to a small team
                                        </li>
                                        <li>Fun to work with</li>
                                    </ul>
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>Requirements</h4>
                                <p>
                                    <ul>
                                        <li>
                                            Excellent knowledge and experience
                                            of React
                                        </li>
                                        <li>
                                            An understanding of good UI/UX
                                            design
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>Nice-to-have experience</h4>
                                <p>
                                    <ul>
                                        <li>
                                            Knowledge of React Native for Web is
                                            an advantage but not essential
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>Our tech stack</h4>
                                <p>
                                    As it stands, our tech stack is as follows:
                                </p>
                                <p>
                                    <ul>
                                        <li>
                                            React Native for our cross-platform
                                            mobile app
                                        </li>
                                        <li>
                                            React JS and Bootstrap for our
                                            front-end web app
                                        </li>
                                        <li>
                                            Node JS (ExpressJS) to build
                                            micro-service APIs
                                        </li>
                                        <li>MySQL to store application data</li>
                                        <li>
                                            Docker containers to deploy
                                            micro-services on AWS
                                        </li>
                                        <li>
                                            Python for our Machine Learning
                                            pipelines{' '}
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>We're remote first</h4>
                                <p>
                                    Although some of us are based in Cambridge,
                                    UK we operate remotely. We meet up in
                                    Cambridge for social events and
                                    collaborative sessions every few months.
                                    It's an excellent opportunity to have fun,
                                    learn and share ideas with the team.
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>Apply for this role</h4>

                                <p>
                                    Send your CV and a short cover letter on
                                    explaining why you would like to join Rnwl
                                    to jobs@rnwl.co. Strictly no agencies.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FrontendDev
