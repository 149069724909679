import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../Header'
import ScrollToTop from '../ScrollToTop'

function MLEngineer() {
    return (
        <div>
            <ScrollToTop />

            <div className="crowdfunding-faq">
                <Header />
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <br />
                            <Link to="/we-are-hiring">
                                &#8592; View all positions
                            </Link>
                            <br />
                            <br />
                            <h1>Machine Learning Engineer</h1>
                            <br />
                            <p>
                                This role is a unique and exciting opportunity
                                for an experienced Machine Learning Engineer to
                                join a start-up in the early formative days.
                                Rnwl is an Insurtech applying machine learning
                                to insurance. You will join a highly
                                experienced, fun and driven team where you will
                                be instrumental in shaping the technical
                                approach that will forever transform the
                                consumer insurance experience. We are here to
                                make Rnwl a great company and to have fun along
                                the way.
                            </p>
                            <p>
                                Check out our{' '}
                                <a href="/app">iOS and Android apps</a> on the
                                app stores.
                            </p>
                            <br />
                            <div className="text-spacer">
                                <h4>Summary</h4>

                                <p>
                                    <ul>
                                        <li>Role: Machine Learning Engineer</li>
                                        <li>
                                            Location: Remote and/or Cambridge,UK
                                        </li>
                                        <li>Type: Full-time, permanent</li>
                                        <li>
                                            Salary: Negotiable depending on the
                                            level of experience
                                        </li>
                                        <li>
                                            Tech stack: Python, PyTorch, NumPy,
                                            Scikit-Learn, Docker, FASTAPI,
                                            Pandas, MySQL
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>Your role</h4>

                                <p>
                                    You will join the machine learning team at
                                    Rnwl to help develop its core machine
                                    learning infrastructure. You will spend most
                                    of your time tackling challenges in the
                                    domain of natural language processing where
                                    you will be designing intelligent systems to
                                    extract and process text. These challenges
                                    may include document layout analysis, text
                                    classification and closed-domain question
                                    and answering to name but a few.
                                </p>

                                <p>
                                    You will report to the Head of Machine
                                    Learning. However, you will quickly find
                                    that our friendly and helpful culture means
                                    you will often work collaboratively with
                                    people across the organisation including
                                    members of the engineering team, product
                                    design team and the CEO.
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>What we are looking for</h4>
                                <p>
                                    You will be highly proactive with a
                                    pragmatic, solution-seeking attitude towards
                                    the problems you will encounter daily. You
                                    will be able to communicate complex concepts
                                    to non-technical team members and explain
                                    your reasoning when suggesting new ideas or
                                    alternative viewpoints.
                                </p>

                                <p>
                                    <ul>
                                        <li>
                                            Minimum 3 years of relevant
                                            experience
                                        </li>
                                        <li>Very high standard of work</li>
                                        <li>
                                            Ability to work independently and
                                            under own initiative
                                        </li>
                                        <li>
                                            Willingness to communicate and
                                            contribute ideas to a small team
                                        </li>
                                        <li>Fun to work with</li>
                                    </ul>
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>Requirements</h4>
                                <p>
                                    <ul>
                                        <li>
                                            Experience using machine learning to
                                            solve natural language processing
                                            problems
                                        </li>
                                        <li>
                                            Experience using a data wrangling
                                            software such as Python’s Pandas or
                                            MySQL
                                        </li>
                                        <li>Strong competency with Python</li>
                                        <li>
                                            Knowledge of how and when to apply
                                            the core ML methodologies such as
                                            SVM, Logistic Regression and deep
                                            learning models (including the key
                                            mathematical principles that
                                            underpin them)
                                        </li>
                                        <li>
                                            Experience deploying models to
                                            production
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>Nice-to-have experience</h4>
                                <p>
                                    <ul>
                                        <li>
                                            Knowledge and/or experience with a
                                            deep learning architecture such as
                                            Karas, PyTorch or Tensorflow
                                        </li>
                                        <li>
                                            Knowledge of a major cloud compute
                                            service such as GCP, AWS or Azure
                                        </li>
                                        <li>
                                            Experience processing PDF documents
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>Our tech stack</h4>
                                <p>
                                    As it stands, our tech stack is as follows:
                                </p>
                                <p>
                                    <ul>
                                        <li>
                                            Python & PyTorch for PDF text
                                            extraction and natural language
                                            processing
                                        </li>
                                        <li>
                                            Python’s FastAPI for model
                                            deployment{' '}
                                        </li>
                                        <li>
                                            Python for our Machine Learning
                                            pipelines
                                        </li>
                                        <li>
                                            React Native for our cross-platform
                                            mobile app
                                        </li>
                                        <li>
                                            React JS and Bootstrap for our
                                            front-end web app
                                        </li>
                                        <li>
                                            Node JS (ExpressJS) to build
                                            micro-service APIs
                                        </li>
                                        <li>MySQL to store application data</li>
                                        <li>
                                            Docker containers to deploy
                                            micro-services on AWS
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>We're remote first</h4>
                                <p>
                                    Although some of us are based in Cambridge,
                                    UK we operate remotely. We meet up in
                                    Cambridge for social events and
                                    collaborative sessions every few months.
                                    It's an excellent opportunity to have fun,
                                    learn and share ideas with the team.
                                </p>
                            </div>

                            <div className="text-spacer">
                                <h4>Apply for this role</h4>

                                <p>
                                    Send your CV and a short cover letter
                                    explaining why you would like to join Rnwl
                                    to jobs@rnwl.co. Strictly no agencies.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MLEngineer
