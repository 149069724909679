import React from 'react'

import Header from '../Header'
import ScrollToTop from '../ScrollToTop'

function DeleteAccount() {
    return (
        <div>
            <ScrollToTop />
            <div className="hero hero--home">
                <Header />
                <div className="strip home-intro">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <h1 className="tagline">
                                    Delete your Rnwl account
                                </h1>
                                <p className="intro">
                                    We’re sorry to see you go.
                                </p>
                                <p className="intro">
                                    To close your Rnwl account:
                                    <br />
                                    <ul>
                                        <li>
                                            Send an email to{' '}
                                            <a href="mailto:deletemyaccount@rnwl.co">
                                                deletemyaccount@rnwl.co
                                            </a>{' '}
                                            with the word “Delete” in the
                                            subject.
                                        </li>
                                        <li>
                                            Make sure you send from the email
                                            address that your Rnwl account is
                                            registered to. You can find this is
                                            in the “Profile” section of the app
                                            or in the “To:” field of any email
                                            we’ve sent you.
                                        </li>
                                        <li>
                                            Your account will be deleted in 2
                                            working days.
                                        </li>
                                    </ul>
                                </p>

                                <p className="intro">
                                    <b>Important:</b> This will permanently
                                    delete your profile and all your account
                                    information. You can’t undo this action.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <img
                                    className="hero-phone-app"
                                    src="images/phone-2.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="benefits"></div>
        </div>
    )
}

export default DeleteAccount
