const { API_ROOT } = require('../config')

const API_PATHS = {
    ENTERPRISE_CONTACT: '/account/enterprise-contact',
}

const API_ENDPOINTS = {
    ENTERPRISE_CONTACT: `${API_ROOT}${API_PATHS.ENTERPRISE_CONTACT}`,
}

module.exports = { API_ENDPOINTS }
