import React from 'react'

import Header from '../Header'
import ScrollToTop from '../ScrollToTop'

function PrivacyPage() {
    return (
        <div className="hero hero--home">
            <ScrollToTop />
            <Header />
            <div className="strip">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h1 className="tagline">Privacy Policy</h1>
                            <p>Last updated: 18th July 2024</p>

                            <div className={'terms'}>
                                <h3 className={'section-heading'}>
                                    Introduction
                                </h3>
                                <div>
                                    <p>
                                        This Privacy Policy (together with our
                                        terms and conditions and any other
                                        documents referred to in it) describes
                                        the type of information that we collect
                                        from you ("you/your") through the use of
                                        our services ("Services"), or the use of
                                        our website{' '}
                                        <a
                                            href={'https://rnwl.co'}
                                            target={'_blank'}
                                            rel={'noreferrer'}
                                            title={'Open Rnwl Website'}
                                        >
                                            https://rnwl.co
                                        </a>{' '}
                                        and all sub-domains and our mobile
                                        application (together, "Website"), how
                                        that information may be used or
                                        disclosed by us and the safeguards we
                                        use to protect it pursuant to the UK
                                        Data Protection Act 2018, the UK GDPR
                                        and the EU GDPR, collectively known as
                                        “Data Protection Legislation”.
                                    </p>
                                    <p>
                                        Our Website and Services may contain
                                        links to third party websites that are
                                        not covered by this Privacy Policy. We
                                        therefore ask you to review the privacy
                                        statements of other websites and
                                        applications to understand their
                                        information practices.
                                    </p>
                                    <p>
                                        We have drafted this Privacy Policy to
                                        be as clear and concise as possible.
                                        Please read it carefully to understand
                                        our policies regarding your information
                                        and how we will treat it. By using or
                                        accessing our Website or the Services,
                                        you agree to the collection, use and
                                        disclosure of information in accordance
                                        with this Privacy Policy. This Privacy
                                        Policy may change from time to time and
                                        your continued use of the Website or the
                                        Services is deemed to be acceptance of
                                        such changes, so please check
                                        periodically for updates.
                                    </p>
                                    <p>
                                        If you do not accept and agree with this
                                        Privacy Policy, you must stop using our
                                        Website immediately.
                                    </p>
                                    <p>
                                        You have the right to make a complaint
                                        at any time to the Information
                                        Commissioner's Office (ICO), the UK
                                        supervisory authority for data
                                        protection issues{' '}
                                        <a
                                            href={'https://www.ico.org.uk'}
                                            target={'_blank'}
                                            rel={'noreferrer'}
                                            title={'Open ICO Website'}
                                        >
                                            www.ico.org.uk
                                        </a>
                                        . We would, however, appreciate the
                                        chance to deal with your concerns before
                                        you approach the ICO, so please contact
                                        us in the first instance. If you live or
                                        work outside the UK or you have a
                                        complaint concerning our personal data
                                        processing activities you may lodge a
                                        complaint with another supervisory
                                        authority.
                                    </p>
                                    <p>
                                        It is important that the personal data
                                        we hold about you is accurate and
                                        current. Please keep us informed if your
                                        personal data changes during your
                                        relationship with us.
                                    </p>
                                    <p>
                                        If you have any comments on this Privacy
                                        Policy, please email them to{' '}
                                        <a
                                            href={'mailto:hello@rnwl.co'}
                                            title={
                                                'Click to send email to hello@rnwl.co'
                                            }
                                        >
                                            hello@rnwl.co
                                        </a>
                                        .
                                    </p>
                                </div>

                                <h3 className={'section-heading'}>
                                    Who We Are
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        Here are the details that Data
                                        Protection Legislation says we have to
                                        give you as a 'data controller':
                                    </li>
                                    <ul>
                                        <li>
                                            Our Website address is{' '}
                                            <a
                                                href={'https://rnwl.co'}
                                                target={'_blank'}
                                                rel={'noreferrer'}
                                                title={'Open Rnwl Website'}
                                            >
                                                https://rnwl.co
                                            </a>
                                        </li>
                                        <li>Our company name is Rnwl Ltd</li>
                                        <li>
                                            Our registered and trading office is
                                            at: Rnwl Ltd, St John’s Innovation
                                            Centre, Cowley Road, Cambridge CB4
                                            0WS, United Kingdom
                                        </li>
                                        <li>
                                            Our data protection officer can be
                                            contacted at{' '}
                                            <a
                                                href={'mailto:hello@rnwl.co'}
                                                title={
                                                    'Click to send email to hello@rnwl.co'
                                                }
                                            >
                                                hello@rnwl.co
                                            </a>
                                        </li>
                                    </ul>
                                    <li>
                                        We respect your right to privacy and
                                        will only process personal information
                                        about or provided by you in accordance
                                        with the Data Protection Legislation.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    What we may collect
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        Personal data, or personal information,
                                        means any information about an
                                        individual from which that person can be
                                        identified. It does not include data
                                        where the identity has been removed
                                        (anonymous data).
                                    </li>
                                    <li>
                                        We may collect, use, store and transfer
                                        different kinds of personal data about
                                        you which we have grouped together as
                                        follows:
                                    </li>
                                    <ul>
                                        <li>
                                            Identity Data: includes first name,
                                            last name, username, date of birth,
                                            gender, nationality, employment
                                            status, information about your
                                            employment, relationship status,
                                            vehicle details, driving history,
                                            offences, medical conditions or
                                            similar identifiers, the same
                                            applies to other drivers that may
                                            have been added. When you email,
                                            phone, live chat or otherwise, we
                                            may collect information such as your
                                            first name, last name, email address
                                            and phone number.
                                        </li>
                                        <li>
                                            Contact Data: includes email
                                            address, postal address, mobile
                                            phone number and landline phone
                                            number Transaction Data: includes
                                            details about insurance products and
                                            other details of our Services you
                                            have obtained from us.
                                        </li>
                                        <li>
                                            Technical Data: includes internet
                                            protocol (IP) address, your login
                                            data, browser type and version, time
                                            zone setting and location, browser
                                            plug-in types and versions,
                                            operating system and platform and
                                            other technology on the devices you
                                            use to access this Website.
                                        </li>
                                        <li>
                                            Profile Data: includes your username
                                            and password, selections made by
                                            you, your interests, preferences,
                                            feedback, survey responses and any
                                            other information supplied by you.
                                        </li>
                                        <li>
                                            Usage Data: includes information
                                            about how you use our Website and
                                            Services.
                                        </li>
                                        <li>
                                            Marketing and Communications Data:
                                            includes your preferences in
                                            receiving marketing from us and our
                                            third parties and your communication
                                            preferences.
                                        </li>
                                        <li>
                                            Interaction Data: includes any
                                            information that you might provide
                                            to any discussion forums on the
                                            Website, this includes but not
                                            limited to reviews and rating
                                            websites, community websites or any
                                            other form of public discussion
                                            forums
                                        </li>
                                        <li>
                                            Cookies Data: like many websites, we
                                            use "cookies" to enhance your
                                            experience and gather information
                                            about visitors and visits to our
                                            websites. Please refer to the "Do we
                                            use 'cookies'?" section below for
                                            information about cookies and how we
                                            use them and what kind.
                                        </li>
                                        <li>
                                            Third Party Information: we may
                                            receive information about you if you
                                            use any of the other websites we
                                            operate or through the Services we
                                            provide. In this case we will have
                                            informed you when we collected that
                                            data that it may be shared
                                            internally and combined with data
                                            collected on our Website. We are
                                            also working closely with third
                                            parties (including, for example,
                                            business partners, suppliers,
                                            sub-contractors, advertising
                                            networks, analytics providers, and
                                            search information providers) and
                                            may receive information about you
                                            from them.
                                        </li>
                                        <li>
                                            Analytics data: includes third-party
                                            analytics services (such as Google
                                            Analytics) to evaluate your use of
                                            the Website, compile reports on
                                            activity, collect demographic data,
                                            analyse performance metrics, and
                                            collect and evaluate other
                                            information relating to our Website
                                            and internet usage. These third
                                            parties use cookies and other
                                            technologies to help analyse and
                                            provide us the data. By accessing
                                            and using the Website, you consent
                                            to the processing of data about you
                                            by these analytics providers in the
                                            manner and for the purposes set out
                                            in this Privacy Policy.
                                        </li>
                                    </ul>
                                    <li>
                                        We also collect, use and share
                                        ‘Aggregated Data’ such as statistical or
                                        demographic data for any purpose.
                                        Aggregated Data may be derived from your
                                        personal data but is not considered
                                        personal data in law as this data does
                                        not directly or indirectly reveal your
                                        identity. For example, we may aggregate
                                        your Usage Data to calculate the
                                        percentage of users accessing a specific
                                        website feature. However, if we combine
                                        or connect Aggregated Data with your
                                        personal data so that it can directly or
                                        indirectly identify you, we treat the
                                        combined data as personal data which
                                        will be used in accordance with this
                                        Privacy Policy.
                                    </li>
                                    <li>
                                        We may need to obtain special category
                                        personal data from you or about you such
                                        as information about medical conditions
                                        and any unspent criminal convictions.
                                        Our lawful basis for processing such
                                        information is Substantial Public
                                        Interest. If you do not provide the
                                        information required, we may not be able
                                        to provide certain services to you.
                                    </li>
                                    <li>
                                        Under Data Protection Legislation we
                                        will ensure that your personal data is
                                        processed lawfully, fairly, and
                                        transparently, without adversely
                                        affecting your rights. We will only
                                        process your personal data if at least
                                        one of the following basis applies:
                                    </li>
                                    <ol>
                                        <li>
                                            You have given consent to the
                                            processing of your personal data for
                                            one or more specific purposes;
                                        </li>
                                        <li>
                                            processing is necessary for the
                                            performance of a contract to which
                                            you are a party or in order to take
                                            steps at the request of you prior to
                                            entering into a contract;
                                        </li>
                                        <li>
                                            processing is necessary for
                                            compliance with a legal obligation
                                            to which we are subject;
                                        </li>
                                        <li>
                                            processing is necessary to protect
                                            the vital interests of you or of
                                            another natural person;
                                        </li>
                                        <li>
                                            processing is necessary for the
                                            performance of a task carried out in
                                            the public interest or in the
                                            exercise of official authority
                                            vested in the controller; and/or
                                        </li>
                                        <li>
                                            processing is necessary for the
                                            purposes of the legitimate interests
                                            pursued by us or by a third party,
                                            except where such interests are
                                            overridden by the fundamental rights
                                            and freedoms of the data subject, in
                                            particular where the data subject is
                                            a child.
                                        </li>
                                    </ol>
                                    <li>
                                        If we receive personal information in
                                        the course of providing our Services to
                                        you from another data subject, you are
                                        responsible for ensuring that the
                                        contents of this Privacy Policy are
                                        brought to their attention and that you
                                        have obtained their express consent to
                                        passing their personal data to us.
                                    </li>
                                    <li>
                                        In some instances, it may be appropriate
                                        for us to combine your information with
                                        other information that we may be holding
                                        about you, such as combining your name
                                        with your geographic location or your
                                        browsing or purchasing history.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    How we may collect and use your data
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        We (or third party data processors,
                                        agents and sub-contractors acting on our
                                        behalf) may collect, store and use your
                                        personal information by way of different
                                        methods to collect data from and about
                                        you including through direct
                                        interactions. You may give us your
                                        information by filling in forms via our
                                        Website or by corresponding with us by
                                        post, phone, email or otherwise. This
                                        includes personal data you provide when
                                        you:
                                    </li>
                                    <ol>
                                        <li>present Website content;</li>
                                        <li>use any of our Services;</li>
                                        <li>
                                            create an account on our Website;
                                        </li>
                                        <li>
                                            subscribe to our Services or
                                            publications;
                                        </li>
                                        <li>
                                            request marketing to be sent to you;
                                        </li>
                                        <li>
                                            enter a competition, promotion or
                                            survey; or
                                        </li>
                                        <li>give us some feedback.</li>
                                    </ol>
                                    <li>
                                        We may use the information in the
                                        following ways:
                                    </li>
                                    <table className={'table table-bordered'}>
                                        <thead>
                                            <tr>
                                                <th>Purpose</th>
                                                <th>
                                                    Legal basis for processing
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    To provide you with updates
                                                    about Rnwl’s business and
                                                    services if you signed up to
                                                    this service via our website
                                                    (and have not notified us
                                                    that you no longer wish to
                                                    receive it).
                                                </td>
                                                <td>Consent</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    To create your Rnwl account
                                                    and identify you as a
                                                    registered user
                                                </td>
                                                <td>
                                                    Fulfilling our contract with
                                                    you
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    To provide the Rnwl service
                                                    as explained in our{' '}
                                                    <a
                                                        href={
                                                            'https://rnwl.co/terms-and-conditions'
                                                        }
                                                        title={
                                                            'Go to Terms & Conditions'
                                                        }
                                                    >
                                                        Terms &amp; Conditions
                                                    </a>
                                                </td>
                                                <td>
                                                    Fulfilling our contract with
                                                    you
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    To communicate with you
                                                    about your account and the
                                                    features and benefits of
                                                    Rnwl
                                                </td>
                                                <td>
                                                    Fulfilling our contract with
                                                    you
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    To provide customer support
                                                    and maintain your account
                                                </td>
                                                <td>
                                                    Fulfilling our contract with
                                                    you
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    To understand our user base
                                                </td>
                                                <td>
                                                    Legitimate interests – to
                                                    understand our users’
                                                    demographics and actions so
                                                    we can improve the relevance
                                                    of our service to users
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    To monitor and improve the
                                                    Rnwl service
                                                </td>
                                                <td>
                                                    Legitimate interests – to
                                                    track and analyse the use of
                                                    our service so we can
                                                    identify areas for
                                                    improvements
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    To comply with regulatory
                                                    and legal requirements
                                                </td>
                                                <td>Legal obligation</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    To enable you to partake in
                                                    a prize draw, competition or
                                                    complete a survey
                                                </td>
                                                <td>
                                                    Fulfilling our contract with
                                                    you and legitimate interests
                                                    - (to study how customers
                                                    use our products/services,
                                                    to develop them and grow our
                                                    business)
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>
                                        In addition, to the above, we may use
                                        your information in the following ways:
                                    </p>
                                    <ol>
                                        <li>
                                            To personalise your Website
                                            experience and to allow us to
                                            deliver the type of content and
                                            product offerings in which you are
                                            most interested.
                                        </li>
                                        <li>
                                            To administer a contest, promotion,
                                            survey or other site feature.
                                        </li>
                                    </ol>

                                    <li>
                                        We will only contact you electronically
                                        in relation to insurance products to the
                                        extent that either this is necessary to
                                        the provision of our services or you
                                        have agreed to be contacted.
                                    </li>
                                    <li>
                                        We may keep a record of those links
                                        which are used the most to enable us to
                                        provide the most helpful information but
                                        we agree to keep such information
                                        confidential and you will not be
                                        identified from this information.
                                    </li>
                                    <li>
                                        If you want to remove all your personal
                                        data, you can let us know at any time by
                                        following the instructions at{' '}
                                        <a href="http://rnwl.co/delete-your-account">
                                            http://rnwl.co/delete-your-account
                                        </a>
                                        . You acknowledge this will permanently
                                        delete all your data and you will no
                                        longer be able to use our services.
                                    </li>
                                    <li>
                                        In some cases, the collection of
                                        personal data may be a statutory or
                                        contractual requirement, and we will be
                                        limited in the services we can provide
                                        you if you don't provide your personal
                                        data in these cases.
                                    </li>
                                    <li>
                                        We will only use your personal data when
                                        the law allows us to. Most commonly, we
                                        will use your personal data in the
                                        following circumstances:
                                    </li>
                                    <ol>
                                        <li>
                                            Where we need to perform the
                                            contract we are about to enter into
                                            or have entered into with you. An
                                            example of this would be where you
                                            have requested us to provide you
                                            with insurance price information.
                                        </li>
                                        <li>
                                            Where it is necessary for our
                                            legitimate interests (or those of a
                                            third party) and your interests and
                                            fundamental rights do not override
                                            those interests.
                                        </li>
                                        <li>
                                            Where we need to comply with a legal
                                            or regulatory obligation
                                        </li>
                                    </ol>
                                    <li>
                                        Generally we do not rely on consent as a
                                        legal basis for processing your personal
                                        data other than in relation to our
                                        marketing communications or sending
                                        third party direct marketing
                                        communications to you via email or text
                                        message. You have the right to withdraw
                                        consent to marketing at any time by
                                        contacting us at{' '}
                                        <a
                                            href={'mailto:hello@rnwl.co'}
                                            title={
                                                'Click to send email to hello@rnwl.co'
                                            }
                                        >
                                            hello@rnwl.co
                                        </a>
                                        , and we will either delete your data
                                        from our systems or move your data to
                                        our "unsubscribe list". However, you
                                        acknowledge this may limit our ability
                                        to provide the best possible services to
                                        you.
                                    </li>
                                    <li>
                                        As already indicated above, with your
                                        permission and/or where permitted by
                                        law, we may also use your data for
                                        marketing purposes which may lead to us
                                        contacting you by email and/or telephone
                                        with information, news and offers on our
                                        Services. We agree that we will not do
                                        anything that we have not agreed to
                                        under this Privacy Policy, and we will
                                        not send you any unsolicited marketing
                                        or spam. We will take all reasonable
                                        steps to ensure that we fully protect
                                        your rights and comply with our
                                        obligations under Data Protection
                                        Legislation and the Privacy and
                                        Electronic Communications (EC Directive)
                                        Regulations 2003, as amended from time
                                        to time.
                                    </li>
                                </ol>

                                <h3
                                    id={'cookies'}
                                    className={'section-heading'}
                                >
                                    Cookies
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        All Cookies used by and on our website
                                        are used in accordance with current
                                        applicable law.
                                    </li>
                                    <li>
                                        We use cookies to distinguish users and
                                        improve our Website. Please look at our
                                        Cookie Policy below for more cookie
                                        information.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Where we store your data and security
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        We may transfer and process your
                                        personal data outside of the UK/EEA to
                                        countries where data protection laws are
                                        less stringent than those in the UK/EEA.
                                        When we transfer your personal data
                                        outside of the UK/EEA we only do so to
                                        entities that offer our users the same
                                        level of data protection as that
                                        afforded by the UK Data Protection Act
                                        2018, UK GDPR and the EU GDPR. We will
                                        only transfer your personal information
                                        to countries that have been deemed to
                                        provide an adequate level of protection
                                        for personal information; or we will use
                                        specific contracts approved for use in
                                        the UK or EEA which give personal
                                        information the same protection it has
                                        in the UK/EEA. For example, the use of
                                        Article 46 UK and EU GDPR safeguard
                                        mechanisms to transfer personal data
                                        endorsed by the UK Government or
                                        European Commission. To find out more
                                        about the transfer mechanism used please
                                        contact us at{' '}
                                        <a
                                            href={'mailto:hello@rnwl.co'}
                                            title={
                                                'Click to send email to hello@rnwl.co'
                                            }
                                        >
                                            hello@rnwl.co
                                        </a>
                                        .
                                    </li>
                                    <li>
                                        Data security is of great importance to
                                        us, and to protect your data we have put
                                        in place suitable physical, electronic
                                        and managerial procedures to safeguard
                                        and secure data collected through our
                                        Website. In addition, we limit access to
                                        your personal data to those employees,
                                        agents, contractors and other third
                                        parties who have a business need to
                                        know. They will only process your
                                        personal data on our instructions and
                                        they are subject to a duty of
                                        confidentiality.
                                    </li>
                                    <li>
                                        We have put in place procedures to deal
                                        with any suspected personal data breach
                                        and will notify you and any applicable
                                        regulator of a breach where we are
                                        legally required to do so.
                                    </li>
                                    <li>
                                        By giving us your personal data, you
                                        agree to this arrangement. We will do
                                        what we reasonably can to keep your data
                                        secure.
                                    </li>
                                    <li>
                                        We have implemented security measures
                                        such as a firewall to protect any data
                                        and maintain a high level of security.
                                    </li>
                                    <li>
                                        Notwithstanding the security measures
                                        that we take, it is important to
                                        remember that the transmission of data
                                        via the internet may not be completely
                                        secure and that you are advised to take
                                        suitable precautions when transmitting
                                        to us data via the internet and you take
                                        the risk that any sending of that data
                                        turns out to be not secure despite our
                                        efforts.
                                    </li>
                                    <li>
                                        If we give you a password upon
                                        registration on our Website, you must
                                        keep it confidential. Please don't share
                                        it.
                                    </li>
                                    <li>
                                        We will keep personal data for as long
                                        as is necessary which is usually the
                                        life of our relationship and up to a
                                        period of seven years after our
                                        relationship has ended. We may however
                                        be required to retain personal data for
                                        a longer period of time to ensure we
                                        comply with our legislative and
                                        regulatory requirements. We review our
                                        data retention obligations to ensure we
                                        are not retaining data for longer than
                                        we are legally obliged to.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Disclosing your information
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        We are allowed to disclose your
                                        information in the following cases:
                                    </li>
                                    <ol className={'section-sub-list'}>
                                        <li>
                                            If we want to sell our business, or
                                            our company, we can disclose it to
                                            the potential buyer.
                                        </li>
                                        <li>
                                            We can disclose it to other
                                            businesses in our group.
                                        </li>
                                        <li>
                                            We can disclose it if we have a
                                            legal obligation to do so, or in
                                            order to protect other people's
                                            property, safety or rights.
                                        </li>
                                        <li>
                                            We can exchange information with
                                            others to protect against fraud or
                                            credit risks.
                                        </li>
                                    </ol>
                                    <li>
                                        We may contract with third parties to
                                        supply services to you on our behalf.
                                        These may include hosting facilities,
                                        search engine facilities, data
                                        processing, advertising and marketing.
                                        In some cases, the third parties may
                                        require access to some or all of your
                                        data. These are the third parties that
                                        have access to your information:
                                    </li>
                                    <ul>
                                        <li>Amazon Web Services (AWS)</li>
                                        <li>Google</li>
                                        <li>Apple</li>
                                        <li>Microsoft</li>
                                        <li>Firebase</li>
                                        <li>Mailchimp</li>
                                        <li>QuoteZone (Seopa)</li>
                                        <li>
                                            QuoteZone’s panel of insurance
                                            providers
                                        </li>
                                        <li>DVLA</li>
                                        <li>BookMyGarage</li>
                                        <li>Postcoder</li>
                                        <li>OpenAI</li>
                                        <li>OneClickCover</li>
                                        <li>Start Rescue</li>
                                    </ul>
                                    <li>
                                        We may disclose your data to our
                                        partners to ensure that the information
                                        you receive as part of our services is
                                        complete and accurate and to provide
                                        marketing to you (where you have
                                        consented to receive marketing).
                                    </li>
                                    <li>
                                        Where any of your data is required for
                                        such a purpose, we will take all
                                        reasonable steps to ensure that your
                                        data will be handled safely, securely,
                                        and in accordance with your rights, our
                                        obligations, and the obligations of the
                                        third party under the law.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Your rights
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        When you submit information via our
                                        Website, you may be given options to
                                        restrict our use of your data. We aim to
                                        give you strong controls on our use of
                                        your data (including the ability to
                                        opt-out of receiving emails from us
                                        which you may do by unsubscribing.
                                    </li>
                                    <li>
                                        Under Data Protection Legislation, you
                                        have the right to:
                                    </li>
                                    <ul>
                                        <li>
                                            request access to, deletion of or
                                            correction of, your personal data
                                            held by us at no cost to you;
                                        </li>
                                        <li>
                                            request that your personal data be
                                            transferred to another person (data
                                            portability);
                                        </li>
                                        <li>
                                            be informed of what data processing
                                            is taking place;
                                        </li>
                                        <li>restrict processing;</li>
                                        <li>
                                            to object to processing of your
                                            personal data; and
                                        </li>
                                        <li>
                                            complain to a supervisory authority.
                                        </li>
                                    </ul>
                                    <li>
                                        You also have rights with respect to
                                        automated decision-making and profiling
                                        as set out in section 11 below.
                                    </li>
                                    <li>
                                        You have the right to ask us not to
                                        process your personal data for marketing
                                        purposes. We will inform you (usually
                                        before collecting your data) if we
                                        intend to use your data for such
                                        purposes or if we intend to disclose
                                        your information to any third party for
                                        such purposes.
                                    </li>
                                    <li>
                                        To enforce any of the foregoing rights
                                        or if you have any other questions about
                                        our Website or this Privacy Policy,
                                        please contact us at{' '}
                                        <a
                                            href={'mailto:hello@rnwl.co'}
                                            title={
                                                'Click to send email to hello@rnwl.co'
                                            }
                                        >
                                            hello@rnwl.co
                                        </a>
                                        .
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Links to other sites
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        Please note that our terms and
                                        conditions and our policies will not
                                        apply to other websites that you get to
                                        via a link from our Website. We have no
                                        control over how your data is collected,
                                        stored or used by other websites and we
                                        advise you to check the privacy policies
                                        of any such websites before providing
                                        any data to them.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>Changes</h3>
                                <ol className={'section-list'}>
                                    <li>
                                        If we change our Privacy Policy, we will
                                        post the changes on this page. If we
                                        decide to, we may also email you.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Automated Decision-Making and Profiling
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        In the event that we use personal data
                                        for the purposes of automated
                                        decision-making and those decisions have
                                        a legal (or similarly significant
                                        effect) on you, you have the right to
                                        challenge to such decisions under Data
                                        Protection Legislation, requesting human
                                        intervention, expressing your own point
                                        of view, and obtaining an explanation of
                                        the decision from us.
                                    </li>
                                    <li>
                                        The right described in section 11.1 does
                                        not apply in the following
                                        circumstances:
                                    </li>
                                    <ol>
                                        <li>
                                            the decision is necessary for the
                                            entry into, or performance of, a
                                            contract between you and us;
                                        </li>
                                        <li>
                                            the decision is authorised by law;
                                            or
                                        </li>
                                        <li>
                                            you have given your explicit
                                            consent.
                                        </li>
                                    </ol>
                                    <li>
                                        Where we use your personal data for
                                        profiling purposes, the following shall
                                        apply:
                                    </li>
                                    <ol>
                                        <li>
                                            Clear information explaining the
                                            profiling will be provided,
                                            including its significance and the
                                            likely consequences;
                                        </li>
                                        <li>
                                            Appropriate mathematical or
                                            statistical procedures will be used;
                                        </li>
                                        <li>
                                            Technical and organisational
                                            measures necessary to minimise the
                                            risk of errors and to enable such
                                            errors to be easily corrected shall
                                            be implemented; and
                                        </li>
                                        <li>
                                            All personal data processed for
                                            profiling purposes shall be secured
                                            in order to prevent discriminatory
                                            effects arising out of profiling.
                                        </li>
                                    </ol>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Terms and Conditions
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        Please also visit our{' '}
                                        <a
                                            href={
                                                'https://rnwl.co/terms-and-conditions'
                                            }
                                            title={'Go to Terms and Conditions'}
                                        >
                                            Terms and Conditions
                                        </a>{' '}
                                        section establishing the use,
                                        disclaimers, and limitations of
                                        liability governing the use of our
                                        Website.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Your Consent
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        By using our Website and by way of
                                        acknowledgment, you consent to our
                                        Privacy Policy.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Dispute Resolution
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        The Parties will use their best efforts
                                        to negotiate in good faith and settle
                                        any dispute that may arise out of or
                                        relate to this Privacy Policy or any
                                        breach of it.
                                    </li>
                                    <li>
                                        If any such dispute cannot be settled
                                        amicably through ordinary negotiations
                                        between the parties, or either or both
                                        is or are unwilling to engage in this
                                        process, either party may propose to the
                                        other in writing that structured
                                        negotiations be entered into with the
                                        assistance of a fully accredited
                                        mediator before resorting to litigation.
                                    </li>
                                    <li>
                                        If the parties are unable to agree upon
                                        a mediator, or if the mediator agreed
                                        upon is unable or unwilling to act and
                                        an alternative mediator cannot be
                                        agreed, we shall arrange for the dispute
                                        to be mediated by an independent third
                                        party.
                                    </li>
                                    <li>
                                        Within 14 days of the appointment of the
                                        mediator, the parties will meet with the
                                        mediator to agree the procedure to be
                                        adopted for the mediation, unless
                                        otherwise agreed between the parties and
                                        the mediator.
                                    </li>
                                    <li>
                                        All negotiations connected with the
                                        relevant dispute(s) will be conducted in
                                        confidence and without prejudice to the
                                        rights of the parties in any further
                                        proceedings.
                                    </li>
                                    <li>
                                        If the parties agree on a resolution of
                                        the dispute at mediation, the agreement
                                        shall be reduced to writing and, once
                                        signed by the duly authorised
                                        representatives of both parties, shall
                                        be final and binding on them.
                                    </li>
                                    <li>
                                        If the parties fail to resolve the
                                        dispute(s) within 60 days (or such
                                        longer term as may be agreed between the
                                        parties) of the mediator being
                                        appointed, or if either party withdraws
                                        from the mediation procedure, then
                                        either party may exercise any right to
                                        seek a remedy through arbitration by an
                                        arbitrator to be appointed by the Law
                                        Society at the request of either party.
                                    </li>
                                    <li>
                                        Any dispute shall not affect the
                                        parties' ongoing obligations under this
                                        Privacy Policy.
                                    </li>
                                </ol>
                            </div>

                            <div className={'terms'}>
                                <h1 id={'cookiepolicy'} className="tagline">
                                    Cookie Policy
                                </h1>

                                <h3 className={'section-heading'}>
                                    Definition
                                </h3>
                                <p>
                                    Cookies are small text files placed on your
                                    device when you visit our site and are used
                                    to make the user's experience more
                                    efficient. We are able to store cookies on
                                    your computer where they are necessary for
                                    the operation of the site however, for
                                    non-essential cookies we need your
                                    permission.
                                </p>

                                <h3 className={'section-heading'}>
                                    How we use Cookies
                                </h3>
                                <p>
                                    We use cookies to recognise you and your
                                    preferences, improve our site's performance
                                    and collect analytical information for
                                    ourselves and our business partners. Without
                                    the knowledge gained we would not be able to
                                    provide the service we do.
                                </p>

                                <h3 className={'section-heading'}>
                                    'Session' and 'Persistent' Cookies
                                </h3>
                                <p>
                                    'Session cookies' allow us to track your
                                    actions during a single browsing session,
                                    but they do not remain on your device
                                    afterwards.
                                </p>
                                <p>
                                    'Persistent cookies' remain on your device
                                    between sessions. We use them to
                                    authenticate you and to remember your
                                    preferences. We can also use them to balance
                                    the load on our servers and improve your
                                    experience on our site.
                                </p>
                                <p>
                                    Session and persistent cookies can be either
                                    first or third party cookies. A first-party
                                    cookie is set by the website being visited;
                                    a third-party cookie is set by a different
                                    website. Both types of cookie may be used by
                                    us or our business partners.
                                </p>
                                <p>Third Party Cookies we use include:</p>
                                <ul>
                                    <li>
                                        Google Analytics. This is a web
                                        analytics service provided by Google,
                                        Inc. The cookies used by Google
                                        Analytics help us to analyse how users
                                        use the site and to count the number of
                                        people who use the site. Google
                                        Analytics stores your IP address
                                        anonymously and neither Rnwl or Google
                                        associate your IP address with any
                                        personally identifiable information.
                                        This information will be held for 5
                                        years.
                                    </li>
                                </ul>
                                <p>Cookies set by Company:</p>
                                <p>
                                    Rnwl sets first party cookies in association
                                    with the Facebook pixel service detailed
                                    above.
                                </p>

                                <h3 className={'section-heading'}>
                                    Disclaimer
                                </h3>
                                <p>
                                    All our cookies are categorised by the role
                                    they fulfil on our website:
                                </p>
                                <ol>
                                    <li>
                                        Strictly Necessary: these are essential
                                        to enable you to move around our website
                                        and use features such as secure
                                        services. Without these cookies such
                                        services could not be provided;
                                    </li>
                                    <li>
                                        Functionality: allow the website to
                                        remember your choices and to personal
                                        certain features. These cookies may be
                                        anonymised and cannot track your
                                        browsing activity on other websites; and
                                    </li>
                                    <li>
                                        Performance: collect information as to
                                        how users use the website. These cookies
                                        don't collect information that
                                        identifies a visitor. The information
                                        collected is aggregated and used to
                                        improve our website.
                                    </li>
                                </ol>
                                <p>
                                    None of the cookies employed are classified
                                    as Behavioural Targeting.
                                </p>
                                <p>
                                    If at any time you wish to disable or delete
                                    our cookies, you may do so through the
                                    settings on your browser. But if you do so
                                    you may not be able to use certain important
                                    features of our service. You can find
                                    additional information at{' '}
                                    <a
                                        href={'https://aboutcookies.org'}
                                        target={'_blank'}
                                        rel={'noreferrer'}
                                        title={'Open AboutCookies.org'}
                                    >
                                        AboutCookies.org
                                    </a>
                                    .
                                </p>
                                <p>
                                    Notwithstanding the audit undertaken
                                    regarding our cookies, it is possible we may
                                    have missed one from our list above. If you
                                    happen to find one that is being set on our
                                    site, please let us know.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPage
