import React from 'react'
import Header from '../Header'

import ScrollToTop from '../ScrollToTop'

function AboutUsPage() {
    return (
        <div>
            <ScrollToTop />
            <div className="hero hero--about">
                <Header />
                <div className="strip strip--about-intro">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="text-spacer">
                                    <h1 className="tagline">Our vision </h1>

                                    <p>
                                        Our vision is to revolutionise how
                                        consumers manage their insurance.
                                    </p>
                                    <p>
                                        We do this by offering an innovative
                                        insurance wallet that gives consumers an
                                        effortless way to manage their insurance
                                        policies, saving them time and money.
                                    </p>
                                    <p>
                                        We're on a mission to be the best in the
                                        world at two things:
                                    </p>
                                    <ul>
                                        <li>The best insurance wallet</li>
                                        <li>The best purchase experience</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex">
                                <img
                                    className="sunset-scoot img-fluid"
                                    src="images/sunset-scoot.png"
                                    alt="woman and child scooting"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="strip strip--what-is-an-insurance-wallet">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-4">
                            <img
                                className="img-fluid"
                                src="images/the-product-rnwl-logo-long.png"
                                alt=""
                            />
                        </div>
                        <div className="col-sm-8 align-middle">
                            <h2>What is an insurance wallet?</h2>
                            <p>
                                An insurance wallet is a digital platform that
                                allows consumers to purchase and manage
                                insurance policies, and where you can store all
                                your insurance policies in one place, just like
                                a physical wallet where you keep all your cards
                                and important documents.
                            </p>
                            <p>
                                The idea behind insurance wallets is to make it
                                easier for consumers to keep track of their
                                insurance policies, understand the coverage they
                                have, access the information they need when they
                                need it, and to be in the best position to make
                                informed decisions to fill gaps or when renewing
                                an expiring policy.
                            </p>
                            <p>
                                <a
                                    href="https://rnwl.page.link/get"
                                    className="btn btn-primary"
                                >
                                    Get Rnwl
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="strip strip--our-story">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-8">
                            <h2>Our story</h2>
                            <p>
                                Rnwl is the brainchild of Goncalo de
                                Vasconcelos, who remains our CEO.
                            </p>
                            <p>
                                Goncalo previously founded and scaled an award
                                winning fintech named SyndicateRoom. The idea
                                for Rnwl came soon after Goncalo stepped down as
                                CEO of SyndicateRoom. A timely auto-renewal car
                                insurance letter arrived through the post.
                                Having more interesting things to do than
                                dealing with insurance, Goncalo thought
                                “wouldn't it be great if there was a company
                                online that would sort this out for me?”. There
                                wasn't. And so Rnwl was born.
                            </p>
                            <p>
                                Rnwl is pronounced "renewal" - we just took out
                                the vowels
                            </p>
                            <p>
                                Goncalo’s vision for Rnwl is to be the best in
                                the world at two things:
                            </p>
                            <ul>
                                <li>The best insurance wallet</li>
                                <li>The best purchase experience</li>
                            </ul>
                            <p>
                                The strong principles behind Rnwl include always
                                doing the right thing for the consumer, and
                                never ever sell their data. Trust is key.
                            </p>

                            <h2 className="mt-5">How we make money</h2>
                            <p>Rnwl makes money in two ways.</p>
                            <ul>
                                <li>
                                    Rnwl is paid a commission whenever a user
                                    purchases an insurance policy via Rnwl.
                                </li>
                                <li>
                                    Rnwl is paid fees from companies that
                                    licence its technology.
                                </li>
                            </ul>

                            <h2 className="mt-5">Open source code</h2>
                            <p>
                                We make parts of our code available for others
                                to use. You can find these in{' '}
                                <a href="https://github.com/rnwl">
                                    our GitHub account
                                </a>
                                :
                            </p>
                            <p>
                                <strong>
                                    <a href="https://github.com/Rnwl/ml-pdf-splitter">
                                        ml-pdf-splitter
                                    </a>
                                </strong>{' '}
                                - Our PDF Splitter library rapidly decodes PDF
                                bytes into text. It works with AWS and allows
                                you to deploy a service to AWS ECS via AWS
                                Copilot, which splits a PDF into chunks and
                                makes parallel calls to an AWS Lambda function
                                that utilises PyMuPDF to rapidly decode PDF
                                bytes into text.
                            </p>
                        </div>
                        <div className="col-sm-4">
                            <img
                                className="img-fluid"
                                src="images/rnwl-coffee.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsPage
