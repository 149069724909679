import React from 'react'
import Header from '../Header'
import MailchimpWaitingListForm from '../MailchimpWaitingListForm'
import ScrollToTop from '../ScrollToTop'

function JoinPage() {
    return (
        <div className="hero hero--inside sunset-color">
            <ScrollToTop />
            <Header />
            <div className="strip sunset">
                <div className="container">
                    <div className="row">
                        <div className="col d-flex">
                            <h2 className="mx-auto sunset__title">
                                Get early access
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <p className="intro">
                                Enter your email address and we'll add you to
                                the waiting list.
                            </p>
                            <p className="intro">
                                We'll let you know as soon as you can take a
                                first look.
                            </p>
                            <MailchimpWaitingListForm />
                        </div>
                        <div className="col-md-6 d-flex">
                            <img
                                className="sunset-scoot img-fluid"
                                src="images/sunset-scoot.png"
                                alt="woman and child scooting"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinPage
