import React from 'react'
import { sendEarlyAccessRequest } from '../api/api'

class MailchimpWaitingListForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            buttonText: 'Request early access',
            formSubmitted: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    // Submit form data to auth service to subscribe the user to early access
    handleSubmit(event) {
        event.preventDefault()
        const form = event.target
        const email = new FormData(form).get('email')
        sendEarlyAccessRequest({
            email,
            onError: console.log,
            onFinally: () => this.setState({ formSubmitted: true }),
        })
    }

    render() {
        return (
            <div>
                {!this.state.formSubmitted ? (
                    <form
                        className="hero-form"
                        name="mc-embedded-subscribe-form"
                        onSubmit={this.handleSubmit}
                    >
                        <input
                            className="hero-form__input form-control"
                            type="email"
                            required
                            name="email"
                            placeholder="Your email"
                            aria-label="Email address"
                        />
                        <button
                            className="hero-form__btn btn btn-primary my-2"
                            type="submit"
                        >
                            Request early access
                        </button>
                    </form>
                ) : (
                    <div>
                        <h3>
                            Congratulations!{' '}
                            <span role="img" aria-label="icon">
                                🎉
                            </span>
                        </h3>
                        <p className="intro"></p>
                        <p className="intro">
                            You are now on our waiting list. We have sent you a
                            confirmation email.
                        </p>
                        <p className="intro">
                            You'll be among the first to get early access.
                        </p>
                    </div>
                )}
            </div>
        )
    }
}

export default MailchimpWaitingListForm
