import React from 'react'

import Header from '../Header'
//import EnterpriseContactForm from '../EnterpriseContactForm'
import ScrollToTop from '../ScrollToTop'

function EnterprisePage() {
    return (
        <div>
            <ScrollToTop />
            <div className="hero ">
                <Header />
                <div className="strip enterprise-intro">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 col--enterprise-hero-content">
                                <h1 className="tagline">
                                    Supercharge your insurance distribution
                                </h1>
                                <p className="intro">
                                    Become your customers' trusted place for all
                                    things insurance
                                </p>
                                <p className="intro">
                                    <a
                                        href="#contact-us"
                                        className="btn btn-primary"
                                    >
                                        Find out more
                                    </a>
                                </p>
                            </div>
                            <div className="col-md-6">
                                <img
                                    className="enterprise-hero-phone"
                                    src="images/our-clients__banks.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="strip strip-whitelabel-embedded">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <h2>
                                    We’re building the future of insurance
                                    distribution with top-tier banks and
                                    insurers
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="strip strip-your-biggest-challenges">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col mb-4">
                                <h2>Your biggest challenges, solved</h2>
                                <p className="intro">
                                    Our insurance wallet delivers the business
                                    impact you're after
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="stat-box">
                                    <h3 className="stat-title">2.05</h3>
                                    <p className="stat-description">
                                        Average number of policies per user
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="stat-box">
                                    <h3 className="stat-title">72%</h3>
                                    <p className="stat-description">
                                        Ratio of quotes run per policy expiring
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="stat-box">
                                    <h3 className="stat-title">£36.50</h3>
                                    <p className="stat-description">
                                        Estimated extra value per year per
                                        retail customer
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="stat-smallprint">
                                    Rnwl data correct as of March 2023
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="stat-box">
                                    <h3 className="stat-title">10%</h3>
                                    <p className="stat-description">
                                        “Reduce churn in core banking by 10%”
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="stat-box">
                                    <h3 className="stat-title">3x</h3>
                                    <p className="stat-description">
                                        “Increase lead conversion by a factor of
                                        three”
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="stat-box">
                                    <h3 className="stat-title">2x</h3>
                                    <p className="stat-description">
                                        “Double the cross-selling rate”
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="stat-smallprint">
                                    “Personalised Bancassurance in Action”, BCG,
                                    November 2022
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="strip strip--unlock-a-world">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h2>Unlock a world of opportunities</h2>
                            </div>
                        </div>
                        <div className="row">
                            <img
                                className="insurance-wallet img-fluid"
                                src="images/insurance-wallet.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>

                {/*<div className="strip">
          <div className="container-fluid">
            <div className="row">
              <div className="col car-col mb-4">
                <div className="contact-car-box">
                  <img src="images/contact-car.png" alt="" className="contact-car" />
                </div>
              </div>

              <div className="col car-col mb-4">
                <div id={'contact-us'} className="contact-text">
                  <EnterpriseContactForm />
                </div>
              </div>
            </div>
          </div>
  </div> */}

                <div className="strip">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col car-col">
                                <div className="contact-car-box">
                                    <img
                                        src="images/contact-car.png"
                                        alt=""
                                        className="contact-car"
                                    />
                                    <div
                                        id={'contact-us'}
                                        className="contact-text"
                                    >
                                        <h2>Contact us</h2>
                                        <a
                                            href={'mailto:enterprise@rnwl.co'}
                                            title={
                                                'Click to send email to enterprise@rnwl.co'
                                            }
                                        >
                                            <img
                                                src="images/contact-us-email.png"
                                                alt=""
                                                className="contact-email"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnterprisePage
