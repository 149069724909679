import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <footer className="footer" style={{ marginBottom: '4rem' }}>
            <div className="container-fluid">
                <div className="row row--footer">
                    <div className="col-md-4">
                        <p className="small smallprint">
                            © {new Date().getFullYear()} Rnwl Ltd. All rights
                            reserved.
                            <br />
                            <br />
                            Rnwl Ltd is authorised and regulated by the
                            Financial Conduct Authority (Register No. 948503).
                            <br />
                            <br />
                            Registered office: St John's Innovation Centre,
                            Cowley Road, Cambridge, CB4 0WS
                        </p>
                    </div>
                    <div className="col-md-3 offset-md-3">
                        <ul className="footer-links">
                            <li>
                                <Link className="footer-link" to="/about-us">
                                    About us
                                </Link>
                            </li>
                            <li>
                                <Link className="footer-link" to="/faqs">
                                    FAQs
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="footer-link"
                                    to="/we-are-hiring"
                                >
                                    We're hiring
                                </Link>
                            </li>
                            <li>
                                <Link className="footer-link" to="/">
                                    For users
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="footer-link"
                                    to="/for-enterprise"
                                >
                                    For enterprise
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="footer-link"
                                    to="/terms-and-conditions"
                                >
                                    Terms and Conditions
                                </Link>
                            </li>
                            <li>
                                <Link className="footer-link" to="/privacy">
                                    Privacy Policy
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="social-icons col-md-2">
                        <a
                            href="https://www.linkedin.com/company/rnwl"
                            className="social-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="images/icon-linkedin.svg"
                                alt="LinkedIn"
                                className="footer-logo"
                            />
                        </a>
                        <a
                            href="https://twitter.com/rnwlco"
                            className="social-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="images/icon-twitter.svg"
                                alt="Twitter"
                                className="footer-logo"
                            />
                        </a>
                        <a
                            href="https://www.facebook.com/rnwl.co"
                            className="social-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="images/icon-facebook.svg"
                                alt="Facebook"
                                className="footer-logo"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
