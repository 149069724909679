import React from 'react'

import Header from '../Header'
import ScrollToTop from '../ScrollToTop'

function TermsAndConditionsPage() {
    return (
        <div className="hero hero--home">
            <ScrollToTop />
            <Header />
            <div className="strip">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h1 className="tagline">Terms and Conditions</h1>
                            <p>Last updated: 18th July 2024</p>

                            <div className={'terms'}>
                                <h3>Introduction</h3>
                                <p>
                                    To help you understand these terms and
                                    conditions, we give you this summary of what
                                    Rnwl does to help you stay on top of your
                                    insurance and related services, as follows:
                                </p>
                                <ul>
                                    <li>
                                        We show you useful information in your
                                        Rnwl account based on what you’ve told
                                        us. We do this by communicating with 3rd
                                        party data providers and our own data
                                        sources in a secure way. For example, we
                                        connect to the DVLA to provide you with
                                        information about your vehicle.
                                    </li>
                                    <li>
                                        We may let you know when you may need to
                                        take action. We may send you an alert.
                                        For example, we may let you know when
                                        you need to have your car MOT tested.
                                    </li>
                                    <li>
                                        We provide ideas and information that we
                                        think could benefit you, based on what
                                        you have told us. We may send you an
                                        alert related to this. We may let you
                                        know when we think you might be
                                        overpaying for insurance.
                                    </li>
                                    <li>
                                        As our service is being actively
                                        developed, we are adding new features
                                        and benefits on an ongoing basis. We may
                                        send you an alert to let you know about
                                        new features and benefits of your
                                        account.
                                    </li>
                                </ul>

                                <h3 className={'section-heading'}>
                                    These terms
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        <strong>What these terms cover.</strong>{' '}
                                        These are the terms and conditions on
                                        which we supply services to you (both
                                        through our website and our mobile
                                        application). Your use of the Rnwl
                                        mobile application is also subject to
                                        the terms and conditions and privacy
                                        policies of the app store you downloaded
                                        it from and if there is a conflict
                                        between the terms of the app store and
                                        our terms and conditions, ours take
                                        priority.
                                    </li>
                                    <li>
                                        <strong>
                                            Why you should read them.
                                        </strong>{' '}
                                        Please read these terms carefully before
                                        you use our services. These terms tell
                                        you who we are, how we will provide
                                        services to you, how you and we may
                                        change or end the contract, what to do
                                        if there is a problem and other
                                        important information. If you think that
                                        there is a mistake in these terms,
                                        please contact us to discuss.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Information about us and how to contact us
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        <strong>Who we are.</strong> We are Rnwl
                                        Ltd a company registered in England and
                                        Wales. Our company registration number
                                        is 12091439 and our registered office is
                                        at St John's Innovation Centre, Cowley
                                        Road, Cambridge, England, CB4 0WS.
                                    </li>
                                    <li>
                                        <strong>How to contact us.</strong> You
                                        can contact us by writing to us at{' '}
                                        <a
                                            href={'mailto:hello@rnwl.co'}
                                            title={
                                                'Click to send email to hello@rnwl.co'
                                            }
                                        >
                                            hello@rnwl.co
                                        </a>
                                    </li>
                                    <li>
                                        <strong>How we may contact you.</strong>{' '}
                                        If we have to contact you, we will do so
                                        by writing to you at the email address
                                        or postal address or on the telephone
                                        number that you provided to us on
                                        registration. Alternatively, we may use
                                        the ‘live chat’ function on this
                                        website.
                                    </li>
                                    <li>
                                        <strong>
                                            "Writing" includes emails.
                                        </strong>{' '}
                                        When we use the words "writing" or
                                        "written" in these terms, this includes
                                        emails.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Our contract with you
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        <strong>Contract contents.</strong> Our
                                        contract with you comprises these terms
                                        and our privacy policy and excludes all
                                        other terms. The contract is made when
                                        you start using our services.
                                    </li>
                                    <li>
                                        <strong>No Charge.</strong> We do not
                                        charge for the services that we provide
                                        to you via this website. The insurance
                                        provider may pay us a fee for every
                                        policy bought via Rnwl.
                                    </li>
                                    <li>
                                        <strong>
                                            We only sell to UK customers.
                                        </strong>{' '}
                                        Our website is solely for the promotion
                                        of our services in the UK and is for the
                                        personal use of individuals only. You
                                        may not provide access to our services
                                        or the results of our services to any
                                        third parties or use them for any
                                        commercial purposes. We do not contract
                                        with anyone whose address is outside the
                                        UK.
                                    </li>
                                    <li>
                                        <strong>
                                            We only contract with and provide
                                            our services to adults.
                                        </strong>{' '}
                                        We only contract with individuals who
                                        are 18 years old or older.
                                    </li>
                                    <li>
                                        <strong>
                                            We do not ourselves provide quotes
                                            or insurance products.
                                        </strong>{' '}
                                        We display relevant quotes from third
                                        party providers. Accordingly, if you
                                        take up a quote you accept it as
                                        provided by the third party provider and
                                        you will be purchasing the insurance
                                        policy directly through it, on its terms
                                        and conditions and subject to its
                                        service procedures. You will not be
                                        purchasing insurance from us.
                                    </li>
                                    <li>
                                        <strong>
                                            We do not provide advice.
                                        </strong>{' '}
                                        We do not provide financial or other
                                        advice in relation to products or
                                        services or their suitability for you,
                                        nor do we provide a recommendation or
                                        endorsement of product or service
                                        providers. It is your responsibility to
                                        discuss your insurance and/or financial
                                        requirements and details with any
                                        provider before taking out an insurance
                                        policy or entering into any other
                                        financial agreement. Failure to do this
                                        could result in you taking out insurance
                                        or entering into a financial product
                                        which is not suitable for your
                                        requirements and which may be inadequate
                                        in the event of a claim. You should
                                        therefore always check the suitability,
                                        adequacy and appropriateness of the
                                        product that is of interest to you.
                                    </li>
                                    <li>
                                        <strong>
                                            Quotes are given in real time.
                                        </strong>{' '}
                                        As a result, no quote lasts for a given
                                        period of time and can change without
                                        notice. The quotes we display are as
                                        given to us by the provider and we have
                                        no additional information in relation to
                                        them.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Our rights to make changes
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        <strong>
                                            Minor changes to our services.
                                        </strong>{' '}
                                        We may change our services:
                                    </li>
                                    <ol className={'alpha'}>
                                        <li>
                                            to reflect changes in relevant laws
                                            and regulatory requirements; and
                                        </li>
                                        <li>
                                            to implement minor technical
                                            adjustments and improvements, for
                                            example to address a security
                                            threat.
                                        </li>
                                    </ol>
                                    <li>
                                        <strong>
                                            More significant changes to the
                                            services and these terms.
                                        </strong>{' '}
                                        We will notify you of any more
                                        significant changes from time to time.
                                        The changes will apply from the date
                                        specified by us without need for further
                                        action on your part.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Providing the services
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        <strong>Duration of provision.</strong>{' '}
                                        We will supply our services to you until
                                        either you or we end the contract as
                                        described in these terms.
                                    </li>
                                    <li>
                                        <strong>
                                            What will happen if you do not give
                                            required information to us.
                                        </strong>{' '}
                                        We do need complete and accurate
                                        information from you so that we can
                                        supply our services to you. If you do
                                        not give us this information within a
                                        reasonable time of us asking for it, or
                                        if you give us incomplete or incorrect
                                        information, we may not be able to
                                        provide our services and (unless we have
                                        failed to provide our services properly)
                                        we are not responsible for the
                                        consequences of you providing incomplete
                                        or incorrect information (which may
                                        mean, for example, that you are offered
                                        a product which does not meet your
                                        requirements or that a policy you take
                                        out is invalid so that claims are
                                        refused in the future).
                                    </li>
                                    <li>
                                        <strong>
                                            Responsibility for Insurance
                                            Application.
                                        </strong>{' '}
                                        You are responsible for the information
                                        given in response to each question in an
                                        insurer’s application form. This
                                        includes information that we have
                                        pre-entered in a form using information
                                        provided by you to us previously.
                                    </li>
                                    <li>
                                        <strong>Credit checks.</strong> Some
                                        insurance providers carry out ‘soft
                                        credit checks’ on your data and credit
                                        history. These are not full credit
                                        searches and will not affect your credit
                                        rating. You can see these soft checks if
                                        you get your credit report but they have
                                        no impact on your credit score
                                    </li>
                                    <li>
                                        <strong>
                                            Use of other data by insurance
                                            providers.
                                        </strong>{' '}
                                        Some insurance providers may use data
                                        they already hold about you (for
                                        example, data from existing products,
                                        loyalty scheme or transactional data) to
                                        assess and rate your cover and determine
                                        your premium.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Ending the contract
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        <strong>
                                            You can always end your contract
                                            with us.
                                        </strong>{' '}
                                        You can end our contract by emailing us
                                        at{' '}
                                        <a
                                            href={'mailto:hello@rnwl.co'}
                                            title={
                                                'Click to send email to hello@rnwl.co'
                                            }
                                        >
                                            hello@rnwl.co
                                        </a>
                                        . The provisions of this contract which
                                        are designed to continue after contract
                                        end will remain in force between us.
                                    </li>
                                    <li>
                                        <strong>
                                            Our rights to end the contract.
                                        </strong>{' '}
                                        We can end our contract by notifying you
                                        in writing at any time. The provisions
                                        of this contract which are designed to
                                        continue after contract end will remain
                                        in force between us.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    If there is a problem with our services
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        <strong>
                                            How to tell us about problems.
                                        </strong>{' '}
                                        If you have any questions or complaints
                                        about our services, please contact us.
                                        You can write to us at{' '}
                                        <a
                                            href={'mailto:hello@rnwl.co'}
                                            title={
                                                'Click to send email to hello@rnwl.co'
                                            }
                                        >
                                            hello@rnwl.co
                                        </a>
                                        .
                                    </li>
                                    <li>
                                        <strong>
                                            Summary of your legal rights.
                                        </strong>{' '}
                                        We are under a legal duty to supply
                                        services that are in conformity with
                                        this contract.
                                    </li>
                                </ol>
                                <p>
                                    Nothing in these terms will affect your
                                    legal rights.
                                </p>

                                <h3 className={'section-heading'}>
                                    Our responsibility for loss or damage
                                    suffered by you
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        <strong>
                                            We are responsible to you for
                                            foreseeable loss and damage caused
                                            by us.
                                        </strong>{' '}
                                        If we fail to comply with these terms,
                                        we are responsible for loss or damage
                                        you suffer that is a foreseeable result
                                        of our breaking this contract or our
                                        failing to use reasonable care and
                                        skill. We are not responsible for the
                                        Insurance Products that you purchase as
                                        a result of using our services.
                                    </li>
                                    <li>
                                        <strong>
                                            We do not exclude or limit in any
                                            way our liability to you where it
                                            would be unlawful to do so.
                                        </strong>{' '}
                                        This includes liability for death or
                                        personal injury caused by our negligence
                                        or the negligence of our employees,
                                        agents or subcontractors; for fraud or
                                        fraudulent misrepresentation; for breach
                                        of your legal rights in relation to the
                                        services as summarised above.
                                    </li>
                                    <li>
                                        <strong>
                                            We are not liable for business
                                            losses.
                                        </strong>{' '}
                                        We only supply our services to consumers
                                        for domestic and private use. If you use
                                        the services for any commercial,
                                        business or re-sale purpose we will have
                                        no liability to you for any loss of
                                        profit, loss of business, business
                                        interruption, or loss of business
                                        opportunity.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    How we may use your personal information
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        <strong>
                                            How we may use your personal
                                            information.
                                        </strong>{' '}
                                        We will only use your personal
                                        information as set out in our privacy
                                        policy,{' '}
                                        <a
                                            href={'https://rnwl.co/privacy'}
                                            title={'Open the privacy policy'}
                                        >
                                            https://rnwl.co/privacy
                                        </a>
                                        .
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Other important terms
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        <strong>Security.</strong> You are
                                        responsible for keeping your account
                                        credentials (including any password)
                                        confidential and must not disclose them
                                        to anyone else.
                                    </li>
                                    <li>
                                        <strong>
                                            We may transfer this agreement to
                                            someone else.
                                        </strong>{' '}
                                        We may transfer our rights and
                                        obligations under these terms to another
                                        organisation. We will always tell you in
                                        writing if this happens and we will
                                        ensure that the transfer will not affect
                                        your rights under the contract.
                                    </li>
                                    <li>
                                        <strong>
                                            You need our consent to transfer
                                            your rights to someone else.
                                        </strong>{' '}
                                        You may only transfer your rights or
                                        your obligations under these terms to
                                        another person if we agree to this in
                                        writing.
                                    </li>
                                    <li>
                                        <strong>
                                            Nobody else has any rights under
                                            this contract.
                                        </strong>{' '}
                                        This contract is between you and us. No
                                        other person shall have any rights to
                                        enforce any of its terms.
                                    </li>
                                    <li>
                                        <strong>
                                            If a court finds part of this
                                            contract illegal, the rest will
                                            continue in force.
                                        </strong>{' '}
                                        Each of the paragraphs of these terms
                                        operates separately. If any court or
                                        relevant authority decides that any of
                                        them are unlawful, the remaining
                                        paragraphs will remain in full force and
                                        effect.
                                    </li>
                                    <li>
                                        <strong>
                                            Even if we delay in enforcing this
                                            contract, we can still enforce it
                                            later.
                                        </strong>{' '}
                                        If we do not insist immediately that you
                                        do anything you are required to do under
                                        these terms, or if we delay in taking
                                        steps against you in respect of your
                                        breaking this contract, that will not
                                        mean that you do not have to do those
                                        things and it will not prevent us taking
                                        steps against you at a later date.
                                    </li>
                                    <li>
                                        <strong>
                                            Which laws apply to this contract
                                            and where you may bring legal
                                            proceedings.
                                        </strong>{' '}
                                        These terms are governed by English law
                                        and you can bring legal proceedings in
                                        respect of our services in the English
                                        courts. If you live in Scotland you can
                                        bring legal proceedings in respect of
                                        our services in either the Scottish or
                                        the English courts. If you live in
                                        Northern Ireland you can bring legal
                                        proceedings in respect of our services
                                        in either the Northern Irish or the
                                        English courts.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Complaints
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        <p>
                                            <strong>
                                                Our commitment to you.
                                            </strong>{' '}
                                            We treat all complaints fairly and
                                            consistently to promptly determine
                                            the subject matter of the complaint
                                            and what action we need to take. All
                                            complaints will be investigated by a
                                            suitably competent member of our
                                            team. Wherever possible, this
                                            responsibility will be allocated to
                                            a person who was not directly
                                            involved in the matter that is the
                                            subject of your complaint. The
                                            member of staff will either have the
                                            authority to settle your complaint
                                            or will have ready access to someone
                                            who has the authority. We decide
                                            what remedial action or redress (or
                                            both) may be appropriate, taking
                                            into account all relevant factors.
                                            You may contact us during our
                                            investigation. If the member of
                                            staff handling your complaint is not
                                            available, another member of our
                                            team will be able to help you.
                                        </p>
                                        <p>
                                            We will aim to resolve your
                                            complaint within three business days
                                            after the date we receive it.
                                        </p>
                                        <p>
                                            If we are unable to resolve your
                                            complaint within three business
                                            days, we will send you a written
                                            acknowledgement of your complaint
                                            within five business days, giving
                                            you the name or job title of the
                                            individual handling the complaint
                                            (together with a copy of these
                                            complaint handling procedures).
                                        </p>
                                        <p>
                                            We will take appropriate steps to
                                            keep you informed of the progress of
                                            the measures we are taking to
                                            resolve the complaint.
                                        </p>
                                        <p>
                                            By the end of eight weeks after
                                            receipt of your complaint, we will
                                            send you:
                                        </p>
                                        <p>EITHER</p>
                                        <p>A final response which:</p>
                                        <ul>
                                            <li>
                                                accepts your complaint and,
                                                where appropriate, offers
                                                redress or remedial action; or
                                            </li>
                                            <li>
                                                offers redress or remedial
                                                action without accepting the
                                                complaint; or
                                            </li>
                                            <li>
                                                rejects the complaint and gives
                                                reasons for doing so
                                            </li>
                                        </ul>
                                        <p>OR</p>
                                        <p>A response which</p>
                                        <ul>
                                            <li>
                                                explains that we are still not
                                                in a position to make a final
                                                response, giving reasons for the
                                                delay and indicating when we
                                                expect to be able to provide a
                                                final response
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Ending the complaint.</strong>{' '}
                                        You may accept our response and/or
                                        withdraw your complaint at any time
                                        during this process, even if we have not
                                        yet issued a final response. We ask that
                                        you confirm this is writing.
                                    </li>
                                    <li>
                                        <strong>Third Party Complaints.</strong>{' '}
                                        If the matter to which your complaint
                                        relates is the responsibility of another
                                        firm (for example an insurer), we will
                                        pass details to them, in writing, within
                                        five business days of receipt of your
                                        complaint and write to you to advise you
                                        of this.
                                    </li>
                                </ol>

                                <h3 className={'section-heading'}>
                                    Information you give us
                                </h3>
                                <ol className={'section-list'}>
                                    <li>
                                        <p>
                                            <strong>Demands and needs.</strong>{' '}
                                            We take the answers you give us via
                                            the questionnaire on our website. We
                                            then transmit them to insurance
                                            providers or partners who can access
                                            quotes from a number of providers. A
                                            provider is someone who provides us
                                            with a quote. They may be an insurer
                                            or a broker. Your personal
                                            information will be sent to
                                            insurance providers in order for
                                            them to provide you with a quote.
                                        </p>
                                        <p>
                                            We work by providing quotes based on
                                            a generic description of cover. For
                                            example, Comprehensive, Third Party
                                            Fire and theft or Third Party only.
                                            This is not a detailed description
                                            of cover. We also specify the
                                            voluntary excess you are willing to
                                            accept over and above the compulsory
                                            excess. Our service delivers you
                                            with quote results which reflect the
                                            generic high level description of
                                            cover you have given us and the
                                            other details you have provided in
                                            the questionnaire.
                                        </p>
                                        <p>
                                            When we give you your quote results,
                                            we list some of the basic
                                            information you have given us,
                                            including:
                                        </p>
                                        <ul>
                                            <li>Vehicle Registration</li>
                                            <li>Vehicle model</li>
                                            <li>The requested start date.</li>
                                            <li>
                                                The type of generic cover you
                                                requested
                                            </li>
                                            <li>The vehicle use</li>
                                            <li>
                                                The names of any additional
                                                drivers
                                            </li>
                                            <li>The voluntary excess</li>
                                        </ul>
                                        <p>
                                            The above, together with your
                                            completed questionnaire, are the
                                            details you have asked us to use in
                                            order to provide our service. These
                                            are your demands and needs for
                                            insurance.
                                        </p>
                                    </li>
                                    <li>
                                        <strong>No claims bonus.</strong> A no
                                        claims bonus will only be valid if it
                                        has been earned on a car in the UK and
                                        has not expired. If you have had a break
                                        in your previous insurance cover, your
                                        no claims bonus may not be accepted by
                                        the insurance provider. Please contact
                                        the insurance provider directly
                                        regarding this.
                                    </li>
                                </ol>
                            </div>

                            <div
                                className={'terms'}
                                style={{ marginTop: '4em' }}
                            >
                                <h3 id={'referrals'}>
                                    Free Car Insurance Prize Draw
                                </h3>

                                <p>
                                    There is currently no prize draw running. We
                                    previously ran monthly prize draws until
                                    31st October 2022.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditionsPage
