import React from 'react'
import Header from '../Header'
import ScrollToTop from '../ScrollToTop'
import SingleFAQ from '../elements/SingleFAQ'

// The answer array can contain both string and image elements.
// The order of the elements will determine the order in which they are displayed.
// For example, you can add an image like this:
// <img className="faqs-image" key="image-1" src="path/to/image.jpg" alt="Description of image" />
// The image will be displayed in the answer section.

// The question needs to be encoded to match the hash
// For example, if the question is 'What is Rnwl?', the hash will be '#What%20is%20Rnwl%3F'
// Remove the last character from the question if it is a question mark so it is not confused as a query param
// So the encoded question will be '#What%20is%20Rnwl' <- no question mark '%3F
// The link to the question will be '/faqs#What%20is%20Rnwl'
// This can then be appended on to the Rnwl website link like so:
// https://rnwl.co/faqs#What%20is%20Rnwl
// And used to navigate directly to the question on the FAQs page
const faqs = [
    {
        question: 'How do I delete a vehicle?',
        encodedQuestion: 'How%20do%20I%20delete%20a%20vehicle',
        answer: [
            "If you don't want to track its details any longer, you'll need to delete the vehicle from your Rnwl app.",
            "1. Head to the vehicle on the 'Details' tab",
            '2. Tap the little down arrow in the top right-hand corner of the card with the vehicle make and registration plate',
            <img
                className="faqs-image"
                key="delete-vehicle-image-1"
                src="images/faqs/delete-vehicle-image-1.png"
                alt="Screenshot from the Rnwl app showing where to find the down arrow on the vehicle card."
            />,
            "3. Tap 'Delete Vehicle' and confirm you want to delete it",
            <img
                className="faqs-image"
                key="delete-vehicle-image-2"
                src="images/faqs/delete-vehicle-image-2.png"
                alt="Screenshot from the Rnwl app showing the confirm delete vehicle modal."
            />,
        ],
    },
]

function FAQsPage() {
    return (
        <div>
            <ScrollToTop />
            <div className="hero hero--home">
                <Header />
                <div className="strip home-intro">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <h1 className="tagline">FAQs</h1>
                            </div>
                        </div>
                        {faqs.map((faq, index) => (
                            <SingleFAQ
                                key={index}
                                question={faq.question}
                                answer={faq.answer}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQsPage
