import React, { useEffect, useRef, useState } from 'react'

function SingleFAQ({ question, answer }) {
    const [active, setActive] = useState(false)
    const contentRef = useRef(null)

    useEffect(() => {
        const hash = window.location.hash
        const encodedQuestion =
            question[question.length - 1] === '?'
                ? `#${encodeURIComponent(question.slice(0, -1))}`
                : `#${encodeURIComponent(question)}`

        if (hash === encodedQuestion) {
            // The setTimeout is used to allow the page to scroll to the correct position before opening the accordion
            setTimeout(() => {
                setActive(true)
            }, 500)
        }
    }, [question])

    useEffect(() => {
        contentRef.current.style.maxHeight = active
            ? `${contentRef.current.scrollHeight}px`
            : '0px'
    }, [active, contentRef])

    const toggleAccordion = () => setActive(!active)

    return (
        <button
            id={
                question[question.length - 1] === '?'
                    ? question.slice(0, -1)
                    : question
            }
            className={`faqs-question-section ${active}`}
            onClick={toggleAccordion}
        >
            <div>
                <div className="faqs-question-align">
                    <h4 className="faqs-question-style">{question}</h4>
                    <img
                        src="images/faqs/down-arrow.png"
                        alt="Arrow"
                        className={
                            active
                                ? `faqs-question-icon faqs-rotate`
                                : `faqs-question-icon`
                        }
                    />
                </div>
                <div
                    ref={contentRef}
                    className={
                        active
                            ? `faqs-answer faqs-answer-divider`
                            : `faqs-answer`
                    }
                >
                    {answer.map((item, index) => (
                        <div key={index} className="faqs-answer-item">
                            {item}
                        </div>
                    ))}
                </div>
            </div>
        </button>
    )
}

export default SingleFAQ
